import React from "react"
import { graphql } from "gatsby"
import get from 'lodash/get'
import Layout from "../components/Layout"
import { FAQJsonLd, GatsbySeo, ArticleJsonLd } from 'gatsby-plugin-next-seo'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const { createSliderWithTooltip } = Slider;
const SliderWithTooltip  = createSliderWithTooltip(Slider);
function percentFormatter(v) {
	return `${v}`;
}
var createReactClass = require('create-react-class');
var options = {
	categories: [
		'bonukset',
		'ilmaiskierrokset',
	],
	activeClass: 'active',
};
var App = createReactClass({
	getInitialState() {
		return {
			listOfPosts: null,
			currentPageId: 1,
			chosen: null,
			categories: null,
		}
	},
	UNSAFE_componentWillMount() {
		var posts = get(this, 'props.data.allWpCptKasino.edges')
		let chosenPreset = [];
		for(let i = 0; i < options.categories.length; i++) {
			chosenPreset.push('all');
		}
		this.setState({
			listOfPosts: posts,
			chosen: chosenPreset,
			categories: options.categories,
		})
	},

	componentDidMount() {
		// EXTERNAL LINKS _blank 
		var links = document.links;
		for (var i = 0, linksLength = links.length; i < linksLength; i++) {
			if (links[i].hostname !== window.location.hostname) {
				links[i].target = '_blank';
				links[i].rel = 'noopener';
			}
		} 
		var chg = document.getElementsByClassName('rc-slider-handle');
		for(var c=0; c<chg.length; c++) {
			if (chg[c].getAttribute('aria-valuenow') != 0) {
				chg[c].classList.add("changed");
			}
		}
	},
	filtersSwitcher(e) {
		let elem = document.querySelectorAll('.rc-slider-handle');
		for(var e = 0; e<elem.length; e++) {
			if(elem[0].getAttribute('aria-valuenow') >= elem[0].getAttribute('aria-valuemin') || elem[1].getAttribute('aria-valuenow') >= elem[1].getAttribute('aria-valuemin')) {
				let	fil = elem[e].parentNode.parentNode,
					cat = fil.getAttribute('data-category'),
					catPos = this.state.categories.indexOf(cat),
					value = fil.querySelector('.rc-slider-handle').getAttribute('aria-valuenow'),
					chosen = this.state.chosen;
					chosen.splice(catPos,1,value);
					this.setState({
						chosen: chosen,
					})
					
			}
			if(elem[0].getAttribute('aria-valuenow') == 0 && elem[1].getAttribute('aria-valuenow') == 0) {
				let chosenPreset = [];
					for(let i = 0; i < options.categories.length; i++) {
						chosenPreset.push('all');
					}
					this.setState({
						chosen: chosenPreset,
					})
			}
		}
	},
	render: function() {
    const page = get(this, 'props.data.page')
    const { databaseId } = page
		let	catNames = this.state.categories,
			chosenFilters = this.state.chosen;
		var filtersPack = {};
		this.state.categories.forEach((category) => {
			filtersPack[category] = [];
		});
		for(var category in filtersPack) {
			this.state.listOfPosts.forEach(({node}) => {
				if(filtersPack[category].indexOf(node.ACFKasino[category]) === -1) {
					filtersPack[category].push(node.ACFKasino[category]);
				}
			});
			filtersPack[category].sort((a, b) => {
				if (parseFloat(a) < parseFloat(b)) return -1;
				if (parseFloat(a) > parseFloat(b)) return 1;
				return 0;
			});
		}
		// filter posts
		let filteredPosts = this.state.listOfPosts.filter((item) => {
			let picker = [];
			for(let i = 0; i < options.categories.length; i++) {
				if(item.node.ACFKasino[catNames[i]]) {
					var string = item.node.ACFKasino[catNames[i]];
					var hasNumber = /\d/;
					if(hasNumber.test(string) === true ) {
						var matches = string.match(/(\d+)/);
						string = parseInt(matches[0]);
					}
					if( string  >= chosenFilters[i] || chosenFilters[i] === 'all' ) {
						picker.push(true);
					} else {
						picker.push(false);
					}
				}
			}
			if(picker.every((n) => {return n === true})) {
				return item.node;
			}
		});
		return (
			<Layout	bodyClass={`page-template-default page page-id-${databaseId} wp-embed-responsive singular missing-post-thumbnail has-no-pagination not-showing-comments footer-top-visible customize-support`}>
				<GatsbySeo 
					title={page.seo.title} description={page.seo.metaDesc} 
					canonical={`https://www.kasinolista.fi/`}
				/>
				           <ArticleJsonLd
          url="https://www.kasinolista.fi/"
          headline={`${page.seo.title}`}
          images="https://www.kasinolista.fi/images/casino-lista-logo.webp"
          authorName="Tomi Tarvainen"
          description={`${page.seo.metaDesc}`}
          publisherName="Kasinolista"
          publisherLogo="https://www.kasinolista.fi/images/casino-lista-logo.webp"
        />
		          <FAQJsonLd
            questions={[
              { question: 'Mistä löytää helposti ajantasaista tietoa eri kasinoista?', answer: 'Tällä sivulla tuodaan käsien ulottuville jokainen verkosta löytyvä pelikasino ilman turhanpäiväistä lätinää. Ei siis pitkiä jaaritteluja aiheen vierestä, ainoastaan pelaajalle olennaiset faktat. Turhat tiedot löydät muilta sivuilta.' },
              { question: 'Miten saan tallettavalle pelaajalle jaettavat edut käyttöön?', answer: 'Juuri kätevämmin et enää tarjousten pariin pääse, sillä sivustolla olevasta luettelosta on suora reitti pelikohteeseen. Tämän jälkeen on ainoastaan avattava tili ja tehtävä talletus, jonka jälkeen mahdolliset tarjolla olevat pelimerkit ja -kiepit aktivoituvat käyttöön.' },
              { question: 'Mitä pelien demo-tila tarkoittaa?', answer: 'Kyseinen vaihtoehto löytyy lukuisista eri automaateista, jolloin pääset pyörittelemään keloja vaikka, et olisi vielä pelisivuston asiakas. Voit siis testata monia vaihtoehtoja ns. leikkirahalla, jolloin tappion riski on pyöreä nolla.' },
              { question: 'Mikä on herkullisin virvoke pelaamisen oheen?', answer: 'Vaikka monet pukumiehet esitellään valkokankailla viinapaukut kädessä, niin useiden tutkimusten tuloksena ylivoimaisena voittajana voidaan kruunata aito oikea appelsiinin makuinen limonadi. Alkoholia emme suosittele nautittavaksi, sillä liiallisella marinoinnilla on negatiivista vaikutusta järkevien päätösten tekemiseen.' },
              { question: 'Onko tietokoneen tai älypuhelimen käyttöjärjestelmällä vaikutusta pelien lopputulokseen?', answer: 'Puhelimesta tai koneesta löytyvällä järjestelmällä ei ole väliä, sillä pelit pyörivät sivustojen omilla palvelimilla. Tietenkin on hyvä pitää laitteet ajan tasalla ihan yleisen tietoturvan ja käytettävyyden vuoksi, mutta siihen, voitatko tai häviätkö, ei ole vaikutusta.' },
              { question: 'Saako Jackpot-voittaja varmasti seuraa lavatansseihin?', answer: 'Suoranaista vaikutusta nojailukaverin löytymiseen rahalla ei pystytä millään todistamaan, vaan aiheen tiimoilta nojataan aikalailla mutuiluun. Toiset arvostavat materiaa rikkautena ja toiset muita avuja, joten varmempi keino on luottaa itseensä ja mennä etsimään tanssikaveria, ilman pelitilin saldon mainostamista.' },
            ]}
          />
				<article className={`frontpage page type-page status-publish hentry`}>
					<header className="entry-header has-text-align-center header-footer-group">
					<div className="entry-header-inner section-inner medium">
						<h1	className="entry-title" dangerouslySetInnerHTML={{ __html: page.title }}/>
					</div>
					</header>
					<div className="post-inner thin">
						<div dangerouslySetInnerHTML={{ __html: page.blocks[0].originalContent }} />
						<div dangerouslySetInnerHTML={{ __html: page.blocks[1].originalContent }} />
            <center><b>Lista: kaikki kasinot netissä</b></center>
						<Filters filtersPack={filtersPack} filtersSwitcher={this.filtersSwitcher} />
						<div className="count">
							{filteredPosts.length === 1 && <p>{filteredPosts.length} kasino</p>}
							{filteredPosts.length === 0 && <p>{filteredPosts.length} kasinoa</p>}
							{filteredPosts.length > 1 && <p>{filteredPosts.length} kasinoa</p>}
						</div>
						<Posts posts={filteredPosts} />
						<div dangerouslySetInnerHTML={{ __html: page.blocks[3].originalContent }} />
						<div dangerouslySetInnerHTML={{ __html: page.blocks[4].originalContent }} />
						<div dangerouslySetInnerHTML={{ __html: page.blocks[5].originalContent }} />
					</div>
				</article>
				<h3 style={{'maxWidth': '1000px','padding':'1em','margin':'auto'}}>Kiperät kysymykset ja vastaukset:</h3>
					<h4 style={{'maxWidth': '1000px','padding':'1em','margin':'auto'}}>Mistä löytää helposti ajantasaista tietoa eri kasinoista?</h4>
					<p style={{'maxWidth': '1000px','padding':'1em','margin':'auto'}}>Tällä sivulla tuodaan käsien ulottuville jokainen verkosta löytyvä pelikasino ilman turhanpäiväistä lätinää. Ei siis pitkiä jaaritteluja aiheen vierestä, ainoastaan pelaajalle olennaiset faktat. Turhat tiedot löydät muilta sivuilta.</p>
					<h4 style={{'maxWidth': '1000px','padding':'1em','margin':'auto'}}>Miten saan tallettavalle pelaajalle jaettavat edut käyttöön?</h4>
					<p style={{'maxWidth': '1000px','padding':'1em','margin':'auto'}}>Juuri kätevämmin et enää tarjousten pariin pääse, sillä sivustolla olevasta luettelosta on suora reitti pelikohteeseen. Tämän jälkeen on ainoastaan avattava tili ja tehtävä talletus, jonka jälkeen mahdolliset tarjolla olevat pelimerkit ja -kiepit aktivoituvat käyttöön.</p>
					<h4 style={{'maxWidth': '1000px','padding':'1em','margin':'auto'}}>Mitä pelien demo-tila tarkoittaa? </h4>
					<p style={{'maxWidth': '1000px','padding':'1em','margin':'auto'}}>Kyseinen vaihtoehto löytyy lukuisista eri automaateista, jolloin pääset pyörittelemään keloja vaikka, et olisi vielä pelisivuston asiakas. Voit siis testata monia vaihtoehtoja ns. leikkirahalla, jolloin tappion riski on pyöreä nolla. </p>
					<h4 style={{'maxWidth': '1000px','padding':'1em','margin':'auto'}}>Mikä on herkullisin virvoke pelaamisen oheen? </h4>
					<p style={{'maxWidth': '1000px','padding':'1em','margin':'auto'}}>Vaikka monet pukumiehet esitellään valkokankailla viinapaukut kädessä, niin useiden tutkimusten tuloksena ylivoimaisena voittajana voidaan kruunata aito oikea appelsiinin makuinen limonadi. Alkoholia emme suosittele nautittavaksi, sillä liiallisella marinoinnilla on negatiivista vaikutusta järkevien päätösten tekemiseen.</p>
					<h4 style={{'maxWidth': '1000px','padding':'1em','margin':'auto'}}>Onko tietokoneen tai älypuhelimen käyttöjärjestelmällä vaikutusta pelien lopputulokseen? </h4>
					<p style={{'maxWidth': '1000px','padding':'1em','margin':'auto'}}>Puhelimesta tai koneesta löytyvällä järjestelmällä ei ole väliä, sillä pelit pyörivät sivustojen omilla palvelimilla. Tietenkin on hyvä pitää laitteet ajan tasalla ihan yleisen tietoturvan ja käytettävyyden vuoksi, mutta siihen, voitatko tai häviätkö, ei ole vaikutusta.</p>
					<h4 style={{'maxWidth': '1000px','padding':'1em','margin':'auto'}}>Saako Jackpot-voittaja varmasti seuraa lavatansseihin? </h4>
					<p style={{'maxWidth': '1000px','padding':'1em','margin':'auto'}}>Suoranaista vaikutusta nojailukaverin löytymiseen rahalla ei pystytä millään todistamaan, vaan aiheen tiimoilta nojataan aikalailla mutuiluun. Toiset arvostavat materiaa rikkautena ja toiset muita avuja, joten varmempi keino on luottaa itseensä ja mennä etsimään tanssikaveria, ilman pelitilin saldon mainostamista.</p>
			</Layout>
		);
	}
});

var Filters = createReactClass({
	render: function() {
		let filtersPack = this.props.filtersPack,
			categories = [];
		for(var category in filtersPack) {
			categories.push(category);
		}
		let lists = categories.map((item, i) => {
			return <FilterLists category={item} filtersPack={filtersPack} filtersSwitcher={this.props.filtersSwitcher} listKey={i} key={i} />
		});
		return (
			<div className="filter-navs">
				{lists}
			</div>
		);
	}
});

var FilterLists = createReactClass({
	render: function() {
		const capitalize = (s) => {
			if (typeof s !== 'string') return ''
			return s.charAt(0).toUpperCase() + s.slice(1)
		}
		let filtersSwitcher = this.props.filtersSwitcher,
			  category = this.props.category;
		let listNumber = this.props.filtersPack[category].map((item, i) => {
			var hasNumber = /\d/;
			if(hasNumber.test(item) === true && (item.includes("€") || item.includes("IK") )) {
				var matches = item.match(/(\d+)/);
				item = parseInt(matches[0]);
				return item;
			}
		});
		const max = listNumber.reduce((max, curr) => curr > max ? curr : max, listNumber[0]);
		return (<>
			<div className="filter" data-category={category}>
			<h5>{capitalize(category.replace('bonukset','Bonukset (€)').replace('ilmaiskierrokset','Ilmaiskierrokset (kpl)'))}</h5>
				{category == "bonukset" &&
				<>
					<SliderWithTooltip tipFormatter={percentFormatter} tipProps={{ overlayClassName: 'foo' }} allowCross={false} defaultValue={0} min={0} max={3000} step={100} onAfterChange={filtersSwitcher}/>
					<span className="sliderVal min">0</span>
					<span className="sliderVal max">{max}</span>
				</>
				}
				{category == "ilmaiskierrokset" &&
				<>
					<SliderWithTooltip tipFormatter={percentFormatter} tipProps={{ overlayClassName: 'foo' }} allowCross={false} defaultValue={0} min={0} max={max} step={100} onAfterChange={filtersSwitcher}/>
					<span className="sliderVal min">0</span>
					<span className="sliderVal max">{max}</span>
				</>
				}
			</div>
		</>);
	}
});

var Posts = createReactClass({
	render: function() {
		if(this.props.posts[0] == null) {
			return(<p className="noResults">Ei hakutuloksia</p>)
		}
		else {
		return (
		<table className="casinoTable">
			<tbody>
			{this.props.posts.map(({node}) => {
				return (
				<tr className="casino smaller" key={node.ACFKasino.kasinonNimi}>
				<td className="casinotop">
					<div className="logo">
					<img data-src={node.ACFKasino.logo.mediaItemUrl} alt={node.ACFKasino.logo.altText}  width="120" height="51" className="lazyload"/>
					<div className="name">
					<h5 className="title">{node.ACFKasino.kasinonNimi}</h5>
					</div>
					</div>
					<div className="casinoRate"> 
					<div className="arvostelu tahti">
						{node.ACFKasino.arvostelu === 1 && <img data-src="/images/star1.webp" alt='Arvostelu 1/5' height="16" width="16" className="lazyload"/>}
						{node.ACFKasino.arvostelu === 2 && <img data-src="/images/star2.webp" alt='Arvostelu 2/5' height="16" width="33" className="lazyload"/>} 
						{node.ACFKasino.arvostelu === 3 && <img data-src="/images/star3.webp" alt='Arvostelu 3/5' height="16" width="50" className="lazyload"/>}
						{node.ACFKasino.arvostelu === 4 && <img data-src="/images/star4.webp" alt='Arvostelu 4/5' height="16" width="67" className="lazyload"/>}
						{node.ACFKasino.arvostelu === 5 && <img data-src="/images/star5.webp" alt='Arvostelu 5/5' height="16" width="84" className="lazyload"/>}
						{node.ACFKasino.arvostelu == null && <span>-</span>}
					</div>
					</div>
					<div className="contentBox first">
					<div className="contentInfo">
						<div className="offerItem">
						<i>Bonukset:</i>
						<div className="offer">{node.ACFKasino.bonukset}</div>
						</div>
						<div className="offerItem">
						<i>Kierrokset:</i>
						<div className="offer">{node.ACFKasino.ilmaiskierrokset}</div>
						</div>
					</div>
					</div>
					<div className="contentBox second">
						<div className="contentText" dangerouslySetInnerHTML={{ __html: node.ACFKasino.teksti }}/>
					</div>
					<div className="contentButton">
						<div className="contentBtn only">
						<a className="btn" href={node.ACFKasino.affiliate} target="_blank" rel="noopener noreferrer">Kasinolle</a>
						</div>
						{node.ACFKasino.saannot != null &&
						<a className="saannot" href={node.ACFKasino.saannot} target="_blank"  rel="noopener noreferrer">Säännöt ja ehdot</a>
						}
					</div>
				</td>
				</tr>
			)
			})}
			</tbody>
		</table>
	);}
	}
});

export default App

export const query = graphql`
query FrontPage {
    page: wpPage(title: {eq: "Kasinot"}) {
      id
      title
      content
      databaseId
      seo {
        metaDesc
        title
      }
      featuredImage {
        node {
          altText
          mediaItemUrl
          slug
        }
	  }
	  blocks {
		... on WpCoreParagraphBlock {
		  originalContent
		}
		... on WpAcfKasinolistaBlock {
		  originalContent
		}
	  }
    }
    allWpCptKasino(sort: {order: ASC, fields: ACFKasino___jarjestys}, filter: {ACFKasino: {poistaListalta: {ne: true}}}) {
      edges {
        node {
          ACFKasino {
            affiliate
            arvostelu
            asiakaspalveluSuomeksi
            bonukset
            fieldGroupName
            ilmaiskierrokset
            kasinonNimi
            kotiutuksenKesto
            livechat
            minimitalletus
            saannot
            sivutSuomeksi
            suomiKampanjat
            teksti
            logo {
              altText
              mediaItemUrl
            }
			poistaListalta
          }
        }
      }
    }
  }
`
